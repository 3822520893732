
export default function profile(state = {}, { type, payload }) {
    switch (type) {
        case "SET_PROFILE":
            return { ...state, profile: payload };
        default:
            return state;
    }

};
