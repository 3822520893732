import React, {
    useEffect,
    useState
} from 'react';
import Button from '@mui/material/Button';
import DataTable from 'react-data-table-component';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_ActionMenuItem
} from 'material-react-table';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useDispatch, useSelector } from 'react-redux';
import { getSelectedAgency, getDropDown } from '../../redux/selector';
import AlertDialog from '../alert/alert'
import FormBuilder from '../formBuilder/formBuilder';
import formData from '../../formData';
import { dataMapper, getSelect } from '../../utils';
import './styles.css';

function TableFormDialog({
    menuName = 'mock'
}) {
    const selectedAgency = useSelector(getSelectedAgency);
    const dropDownStore = useSelector(getDropDown) || {};
    const selectFields = getSelect(formData, menuName);
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const [ddFields, setddFields] = useState(selectFields);
    const [isLoading, setTableLoad] = useState(false);
    const [slideForm, setSlideForm] = useState(true);
    const [isEdit, setEdit] = useState(true);
    const [isAdd, setAdd] = useState(true);
    const [open, setOpen] = useState(false);
    const [defaultFieldValue, setDefaultValue] = useState({});
    const [tableData, setTableData] = useState([]);
    const [agencyList, setAgencyList] = useState(dropDownStore.agency || []);

    const fetchList = (url, payload) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("NETWORK RESPONSE ERROR");
                }
            })
            .then(data => {
                setTableLoad(false);
                setTableData(dataMapper(data.data));
                // setTableData(data.data);
                // document.getElementById("output").innerText = JSON.stringify(data, undefined, 2);
            })
            .catch((error) => {
                console.error("FETCH ERROR:", error);
                setTableData(dataMapper([{
                    ...formData[menuName]?.mockData[0],
                    id: 1
                }]));
            });
    };
    const callFetchList = (reqPayload = {}) => {
        // setSlideForm(false);
        // setTableLoad(true);
        const { url, payload = {} } = formData[menuName]?.apiDetails?.list || {};
        if (url) { fetchList(url, { ...payload, ...reqPayload }); }
        else { setTableLoad(false); }
    };

    const resetForm = () => {
        setDefaultValue({});
        callFetchList();
    };

    const deleteFromList = (url, payload) => {
        fetch(url, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("NETWORK RESPONSE ERROR");
                }
            })
            .then(data => {
                console.log(data);
                setTableLoad(false);
                setTableData(data.data);
                callFetchList();
                // document.getElementById("output").innerText = JSON.stringify(data, undefined, 2);
            })
            .catch((error) => console.error("FETCH ERROR:", error));
    }
    useEffect(() => {
        setAgencyList(dropDownStore.agency || []);
    }, [dropDownStore.agency]);
    useEffect(() => {
        setTableData([]);
        // setSlideForm(false);
        setDefaultValue({});
        // setddFields(getSelect(formData, menuName));
        formData[menuName]?.apiDetails?.startUp?.(dispatch, {
            agency: selectedAgency || 0
        });
        callFetchList();

    }, [menuName]);

    const handleDelete = (id) => {
        setOpen(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const tableProps = {
        columns: [
            ...formData[menuName].tableCol
        ],
        data: tableData,
        pagination: true
    };
    const alertProps = {
        dialogTitle: 'Notification',
        dialogContent: 'Are you sure you want to delete ?',
        cancelText: 'Cancel',
        okText: 'Ok',
        open,
        handleClose,
        handleSubmitCB: (id) => {
            deleteFromList(formData[menuName]?.apiDetails?.delete, { id });
        }
    }
    const reactTableProp = useMaterialReactTable({
        columns: tableProps.columns.map((colData) => {
            return {
                header: colData.name,
                accessorFn: colData.selector
            }
        }),
        data: tableData,
        enableStickyHeader: true,
        enableColumnPinning: true,
        initialState: { columnPinning: { right: ['mrt-row-actions'] } },
        enableRowActions: true,
        positionActionsColumn: 'last',
        state: {
            isLoading
        },
        renderRowActionMenuItems: ({ closeMenu, row, table }) => [
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
                icon={<Visibility />}
                key="view"
                label="View"
                table={table}
                onClick={() => {
                    setEdit(false);
                    setAdd(false);
                    setSlideForm(true);
                    setDefaultValue(row.original);
                    closeMenu();
                }} />,
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
                icon={<Edit />}
                key="edit"
                label="Edit"
                table={table}
                onClick={() => {
                    setEdit(true);
                    setAdd(false);
                    setSlideForm(true);
                    setDefaultValue(row.original);
                    closeMenu();
                }} />,
            <MRT_ActionMenuItem
                icon={<Delete />}
                key="delete"
                label="Delete"
                table={table}
                onClick={() => {
                    handleDelete(row?.original?.id);
                    closeMenu();
                }} />,
            <MRT_ActionMenuItem
                icon={<Delete />}
                key="addSimilar"
                label="Add Similar"
                table={table}
                onClick={() => {
                    setEdit(true);
                    setAdd(true);
                    // setSlideForm(!slideForm);
                    setDefaultValue(row.original);
                }} />,
        ],
        // renderRowActions: ({ row, table }) => <>
        //     <div className="icon-wrapper">
        //         <Tooltip title="View"><VisibilityIcon sx={{ color: 'blue' }} onClick={() => {
        //             setEdit(false);
        //             setAdd(false);
        //             setSlideForm(true);
        //             setDefaultValue(row.original);
        //         }} /></Tooltip>
        //         <Tooltip title="Edit"><EditIcon sx={{ color: 'green' }} onClick={() => {
        //             setEdit(true);
        //             setAdd(false);
        //             setSlideForm(true);
        //             setDefaultValue(row.original);
        //         }} /></Tooltip>
        //         <Tooltip title="Delete"><DeleteIcon sx={{ color: 'red' }} onClick={() => {
        //             handleDelete();
        //         }} /></Tooltip>
        //     </div>
        // </>,
        renderTopToolbarCustomActions: () => (<>
            <div className='menu-comp-title'>
                {formData[menuName].tableTitle}
            </div>
            {(!slideForm && !formData[menuName].hideForm) ? <Button
                class="add-agency"
                onClick={() => {
                    setEdit(true);
                    setAdd(true);
                    // setShowForm(!showForm);
                    setSlideForm(true);
                    setDefaultValue({});
                }}
            >+ Add</Button> : ''}
        </>),
        // muiTableHeadCellProps: {
        //     //simple styling with the `sx` prop, works just like a style prop in this example
        //     sx: {
        //       fontWeight: 'normal',
        //       fontSize: '14px',
        //     },
        //   },
    });

    const getAction = (actionName) => {
        switch(actionName){
            case 'add':
                return 'Add';
            case 'addSimilar':
                return 'Add Similar';
            case 'edit':
                return 'Edit';
            default:
                return '';
        }
    }
    return (
        <div className="section-wrapper">
            <div class="bul-container">
                <div class="container-fluid">
                    {open ? <AlertDialog {...alertProps} /> : ''}
                    <div className={`slideWrapper ${slideForm ? 'expand' : 'collapsed'}`}>
                        <div class="bul-button-sec">
                            <div className='menu-comp-title'>
                                {formData[menuName].formTitle}
                            </div>
                            <Button
                                class="btn back-btn add-agency"
                                onClick={() => {
                                    // setEdit(false);
                                    // setAdd(false);
                                    // setSlideForm(!slideForm);
                                    setDefaultValue({});
                                }}
                            >Clear</Button>
                        </div>
                        <FormBuilder
                            key={menuName}
                            defaultFieldValue={defaultFieldValue}
                            isEdit={isEdit}
                            resetForm={resetForm}
                            isAdd={isAdd}
                            menuName={menuName}
                            callFetchList={callFetchList} />
                    </div>
                    {/* <DataTable
                        {...tableProps}
                    /> */}
                    <div className='tableWrapper'>
                        {formData[menuName].tableAgency ?
                            <Autocomplete
                                key={`${menuName}_agency`}
                                sx={{
                                    'width': 200
                                }}
                                disablePortal
                                options={agencyList.map(({ id, name }) => ({
                                    id, label: name
                                }))}
                                ListboxProps={{ style: { zIndex: 5 } }}
                                onChange={(_e, selectVal) => {
                                    callFetchList({ agency: selectVal.id || 0 });
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={'Agency'}
                                />}
                            /> : ''
                        }
                        <MaterialReactTable table={reactTableProp} />
                    </div>
                    {/* {showForm ?
                        <Dialog
                            open={showForm}
                            onClose={() => {
                                setShowForm(false);
                            }}
                            maxWidth='lg'
                        >
                            <DialogTitle>
                                <div class="bul-button-sec">
                                    <div className='menu-comp-title'>
                                        {formData[menuName].formTitle}
                                    </div>
                                    <button
                                        class="btn back-btn add-agency"
                                        onClick={() => {
                                            setEdit(true);
                                            setShowForm(!showForm);
                                            setDefaultValue({});
                                        }}
                                    >{'Back'}</button>
                                </div>
                            </DialogTitle>
                            <FormBuilder {...formBuilderProps} />
                        </Dialog> : ''} */}
                </div>
            </div>
        </div>
    );
}

export default TableFormDialog;
