import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';
import Forms from './form';
import Tableworkorder from './table';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function MiniDrawer() {

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };



  

 
const headerProps={
  open,handleDrawerOpen
}
const menuProps={
  setOpen,open
}
const tableProps={
  setOpenDialog
}
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
  return (
    
       
    <Box sx={{ display: 'flex' }}>
      
      <HeaderMMS {...headerProps} />
      <MenuMMS {...menuProps} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
      <Grid className='bg-slate-100 '>
      <DrawerHeader />
      {/* <Forms /> */}
      <div className='rounded p-3 text-lg text-left font-semibold'>
          <h2>Critical Spare List</h2>
          </div>
      <BootstrapDialog
      className="mms-full-dig"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='bg-sky-600 text-white'>
        Critical Spare List
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Forms /> 
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
      </BootstrapDialog>
      
      <Box component="main"  className='bg-slate-100'>
      <Tableworkorder {...tableProps} />
      </Box>
       </Grid>
      </Box>
    </Box>
  );
}
