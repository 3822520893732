import React from "react";
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import avathar from '../../Assets/profile.png';
import { useNavigate } from "react-router-dom";
import { Settings, Handyman } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HandymanIcon from '@mui/icons-material/Handyman';
import InsightsIcon from '@mui/icons-material/Insights';
import BuildIcon from '@mui/icons-material/Build';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import TaskIcon from '@mui/icons-material/Task';
import EmailIcon from '@mui/icons-material/Email';
import BackupIcon from '@mui/icons-material/Backup';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CameraIcon from '@mui/icons-material/Camera';
import PinIcon from '@mui/icons-material/PushPin';
import UnpinIcon from '@mui/icons-material/PushPinOutlined';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;
function MenuMMS({ setOpen, open }) {
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
 

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );
  const handleMenuClick = (menuName) => {
    if (menuName === 'Dashboard') {
      navigate("/dashboard");
    } else if (menuName === 'Work Order') {
      navigate("/workorder");
    } else if (menuName === 'Warrenty') {
      navigate("../warrenty");
    }
    else if (menuName === 'Maintenance') {
      navigate("../maintenance");
    }
    else if (menuName === 'Configure') {
      navigate("../configure");
    }
    else if (menuName === 'Critical Spare List') {
      navigate("../criticalSpareList");
    }
    else if (menuName === 'Settings') {
      navigate("../settings");
    }
    else if (menuName === 'Task') {
      navigate("../task");
    }
    else if (menuName === 'Logs') {
      navigate("../logs");
    }
    else if (menuName === 'Email') {
      navigate("../email");
    }
    else if (menuName === 'ERT') {
      navigate("../ert");
    }
    else if (menuName === 'Calibration Order') {
      navigate("../calibrationOrder");
    }
    // console.log();
  }
  const navigate = useNavigate();
  const theme = useTheme();

 
  const [pinned, setPinned] = React.useState(false);

  const handleDrawerToggle = () => {
    if (pinned) {
      setPinned(false); // If pinned, unpin it and collapse the drawer
      setOpen(false);
    } else {
      setOpen(!open); // Otherwise, toggle the drawer
    }
  };

  const handlePinToggle = () => {
    setPinned(!pinned);
    setOpen(!pinned); // Ensure drawer remains open when pinned
  };
  return (
    <Drawer variant="permanent" open={open} className='bg-sky-500 mms-left-menu'>
      <DrawerHeader className='bg-sky-500'>
        <IconButton onClick={handleDrawerToggle}>
        {pinned ? <UnpinIcon /> : <MenuIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className='bg-sky-500 text-white'>
        {/* <div className='text-center mt-5'>
            <img src={avathar} alt="" className="text-center m-auto" />
            <h2 className='mt-3 text-lg'>Aaron Chavez</h2>
            <span className='text-sm mb-3'>Administrator</span>
          </div> */}

        {['Dashboard', 'Logs', 'Work Order', 'Warrenty', 'Maintenance', 'Calibration Order', 'Critical Spare List' , 'Task', 'Email', 'Configure', 'DB Backup', 'ERT', 'Settings'].map((text, icon) => (

          <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                handleMenuClick(text)

              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                {icon === 0 && <Settings />}
                {icon === 1 && <FileOpenIcon />}
                {icon === 2 && <HandymanIcon />}
                {icon === 3 && <InsightsIcon />}
                {icon === 4 && <BuildIcon />}
                {icon === 5 && <CameraIcon />}
                {icon === 6 && <AcUnitIcon />}
                {icon === 7 && <TaskIcon />}
                {icon === 8 && <EmailIcon />}
                {icon === 9 && <AssessmentIcon />}
                {icon === 10 && <BackupIcon />}
                {icon === 11 && <SettingsSuggestIcon />}
                {icon === 12 && <DashboardIcon />}
               
               
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
 <List>
          <ListItem button onClick={handlePinToggle}>
            <ListItemIcon>{pinned ? <UnpinIcon /> : <PinIcon />}</ListItemIcon>
            <ListItemText primary={pinned ? 'Unpin Menu' : 'Pin Menu'} />
          </ListItem>
        </List>
    </Drawer>
  );
}

export default MenuMMS;