
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Employee",
    "tableTitle": "Employee List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/ship/shipclass/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/ship/shipclass`,
        edit: `${baseUrl}/api/ship/shipclass/edit`,
        delete: `${baseUrl}/api/ship/shipclass/remove`
    },
    "tableCol": [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Employee Code',
            selector: row => row.empCode,
            sortable: true
        },
        {
            name: 'Department',
            selector: row => row.department,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.empEmail,
            sortable: true
        },
        {
            name: 'Phone',
            selector: row => row.empPhone,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Name',
                    name: 'name',
                    "required": true
                },
                {
                    type: 'text',
                    label: 'Employee Code',
                    name: 'empCode',
                    "required": true
                },
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department',
                    "options": [
                        "Agency",
                        "logistics"
                    ],
                    "required": true
                },
                {
                    type: 'email',
                    label: 'Email',
                    name: 'empEmail',
                    "required": true
                },
                {
                    type: 'number',
                    label: 'Phone',
                    name: 'empPhone'
                }
            ]
        }
    ],
    "mockData": [{
        "name": "Kumar",
        "empCode": "code007",
        "department": 1,
        "empEmail": "asds@sf.com",
        "empPhone": 23123
    }]
}