import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

function DateSelect() {
  return (
    <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="jobPermit">Select</InputLabel>
                <Select className='mms-select'
                  name="jobPermit"
                  label="Job Permit"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Today</MenuItem>
                  <MenuItem value={20}>Yesterday</MenuItem>
                  <MenuItem value={30}>Current Week</MenuItem>
                  <MenuItem value={40}>Last Week</MenuItem>
                  <MenuItem value={50}>Current Month</MenuItem>
                  <MenuItem value={60}>Last Month</MenuItem>
                  <MenuItem value={70}>Last 3 Months</MenuItem>
                  <MenuItem value={80}>Last 6 Month</MenuItem>
                  <MenuItem value={90}>Current Year</MenuItem>
                  <MenuItem value={0}>Custom</MenuItem>
                </Select>
              </FormControl>
  );
}

export default DateSelect;
