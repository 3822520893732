import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import Forms from './form';
// import Tableworkorder from './table';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function MiniDrawer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    
    const [openDialog, setOpenDialog] = React.useState(false);
    const [formData, setFormData] = useState({
        slno: '',
        dateofpurchase: null,
        make: '',
        poNumber: '',
        invoiceNumber: '',
        date: '',
        warrentystart: '',
        warrentyend: '',
        warrentyPeriod: '',
        expiryNotification: '',
        vendorSupplier: '',
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        dateinvoicenum: null,
        dateinvoice: null
      });


    const handleClickOpen = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    const handleSubmitForm = () => {
      console.log(formData);
    }
      const handleClearForm = () => {
        setFormData({
            pmno: '',
            area: '',
            facility: '',
            equipment: '',
            subequipment: '',
            device: '',
            instrumenttag: '',
            jobnature: '',
            lastperformed: '',
            frequency: '',
            tolerence: '',
            repeat: '',
            advancenotification: '',
            status: ''
        });
      };

     

    const headerProps = {
        open, handleDrawerOpen
    }
    const menuProps = {
        setOpen, open
    }
    const tableProps = {
        setOpenDialog
    }
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));
    return (


        <Box sx={{ display: 'flex' }}>
      
        <HeaderMMS {...headerProps} />
        <MenuMMS {...menuProps} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
        <Grid className='bg-slate-100 '>
        <DrawerHeader />
        {/* <Forms /> */}
        <Box className="w-full">
      <Grid className='bg-slate-100 '>
        <form>
          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200 '>ERT</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3 mms-date-field'>
              
                <TextField size='small' label="01" 
                  name="001"
                  disabled />

              <TextField size='small' label="SM00002" variant="outlined"
                  name="slno"
                  value={formData.slno}
                  disabled
                  onChange={handleInputChange} />
                   <TextField size='small' label="Rajesh" variant="outlined"
                  name="slno"
                  value={formData.slno}
                  onChange={handleInputChange} 
                  disabled/>
             
                    <Button
      component="label"
      role={undefined}
      variant="contained"
      disabled
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      Photo
      <VisuallyHiddenInput
        type="file"
        onChange={(event) => console.log(event.target.files)}
        multiple
      />
    </Button>
                  <TextField size='small' label="999999977" variant="outlined"
                  name="slno"
                  value={formData.slno}
                  onChange={handleInputChange} disabled />
                  <TextField size='small' label="Admin" variant="outlined"
                  name="slno"
                  value={formData.slno}
                  onChange={handleInputChange}disabled />
           
         
                    <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="planFacility">Member</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  value={formData.planFacility}
                  label="OrderForm"
                  onChange={handleInputChange}
                  disabled
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>

          </div>
        
        
        

          {/* <div className='mt-5 flex justify-center'>
            <div className=''>
              <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
            </div>
            <div className='mx-1'>
              <Button variant="contained" className='mx-1.5' >
                Add Similar
              </Button>
            </div>
            <div>
              <Button variant="contained" className='bg-amber-500' onClick={handleClearForm}>
                Clear
              </Button>
            </div>

          </div> */}
        </form>
      </Grid>
    </Box>
       
        
     
         </Grid>
        </Box>
      </Box>
    );
}
