import React, { useMemo } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DateSelect from '../dateSelect/dateSelect';
const data = [
  {
    taskname: "test",
    tasknumber: "00001",
    taskdate: "13Oct2024",
    action:"Test",
  },
  {
    taskname: "test1",
    tasknumber: "00002",
    taskdate: "31Aug2024",
    action:"Test1",
  }
];
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function Tableworkorder({setOpenDialog}) {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.taskname, 
        id: "taskname",
        header: "Task Name",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.tasknumber, 
        id: "tasknumber",
        header: "Task Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.taskdate, 
        id: "taskdate",
        header: "Task Date",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.action, 
        id: "action",
        header: "Action",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      }
      
    ],
    []
  );

  const table = useMaterialReactTable({
    data,
    columns,
     renderTopToolbarCustomActions : () => (<>
     <Box >
     <Grid className='bg-slate-100 '>
     
      <Button
          variant="contained" color="success"
          onClick={() => {setOpenDialog(true)
          }}
      >+ Add</Button> 
      <Button variant="contained" className='bg-amber-500 mx-0.5 mlr-5' >
      Export
    </Button>
    <Button variant="contained" className='bg-violet-500' >
      Import
    </Button>
    <DateSelect></DateSelect>
      </Grid>
      </Box>
  </>),
  });

  return <MaterialReactTable table={table} />;
}
