
import mock from './mock.js';
/** configure */
import area from './configure/area.js';
import facility from './configure/facility.js';
import equipment from './configure/equipment.js';
import subEquipment from './configure/subEquipment.js';
import department from './configure/department.js';
import device from './configure/device.js';
import employee from './configure/employee.js';
import jobNature from './configure/jobNature.js';
import jobPriority from './configure/jobPriority.js';
import task from './configure/task.js';
import vendor from './configure/vendor.js';
import workPermit from './configure/workPermit.js';

export default {
    /** configure */
    area,
    facility,
    equipment,
    subEquipment,
    department,
    device,
    employee,
    jobNature,
    jobPriority,
    task,
    vendor,
    workPermit,

    mock
};
