import { baseUrl, headers } from "../constants";

export const getCall = (url, body, successCb, errCb, method) => {
    fetch(url, {
        method: method || "POST",
        body: body ? JSON.stringify(body) : null,
        headers
    })
    .then(res => res.json())
    .then(res => {
        successCb(res.data);
    })
    .catch((error, res ,a,b) => {
        errCb?.();
        console.error(error);
    })
};

export const getJobCard = (cb) => {   
    getCall(
        `${baseUrl}/api/service/job/card`, 
        {},
        (data) => {
            cb(data);
        }
    );
}

export const getJobDetails = async (body) => {   
    return await fetch( `${baseUrl}/api/service/job/list`, {
        method: "POST",
        body: body ? JSON.stringify(body) : null,
        headers
    })
    .then(res => res.json())
    .then(res => {
        return res?.data?.[0];
    })
    .catch((error, res ,a,b) => {
        console.error(error);
    });
}

export const setAckCall = (id, cb) => {
    getCall(
        `${baseUrl}/api/service/job/ack`, 
        { id, job_status: 'Acknowledged' },
        (data) => {
            console.log(data);
            cb(data);
        },
        (data, res ,a,b) => {
            cb(data);
        }
    );
};

export const cancelApp = (id, cb) => {
    getCall(
        `${baseUrl}/api/service/appointment/remove`, 
        { id },
        (data) => {
            console.log(data);
            cb?.(data);
        },
        (data, res ,a,b) => {
            cb?.(data);
        },
        'put'
    );
};

export const sendPda = (cb, id) => { 
    const formData = new FormData();
    formData.append("job_id", id); 
    fetch(`${baseUrl}/api/service/pda/send`, {
        method: "POST",
        headers: {
            Accept: "application/pdf, application/xml, application/octetstream"
        },
        body: formData,
    })
    .then(res => res.blob())
    .then(res => {
        console.log(res);
        // successCb(res.data);
    })
    .catch((error, res ,a,b) => {
        console.log(error, res ,a,b);
        console.error(error);
    })
};

export const pdaPdf = (cb, body, pdaNum) => {  
    const formData = new FormData();
    formData.append("job_id", body.job_id); 
    fetch(`${baseUrl}/api/service/pda/pdf`, {
        method: "POST",
        headers: {
            Accept: "application/pdf, application/xml, application/octetstream"
        },
        body: formData,
    })
    .then(res => res.blob())
    .then(res => {
        console.log(res);
        const fileName = pdaNum || 'PDA';
        //Check the Browser type and download the File.
        var isIE = false || !!document.documentMode;
        if (isIE) {
            window.navigator.msSaveBlob(res, fileName);
        } else {
            var url = window.URL || window.webkitURL;
            const link = url.createObjectURL(res);
            var a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("href", link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        // successCb(res.data);
    })
    .catch((error, res ,a,b) => {
        console.log(error, res ,a,b);
        console.error(error);
    })
}
