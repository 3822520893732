import { combineReducers } from 'redux';
import dropDown from './dropDown';
import profile from './profile';
import common from './common';

const reducer = combineReducers({
    dropDown,
    profile,
    common
});

export default reducer;
