import React from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { useState } from 'react';
export default function Forms() {
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [formData, setFormData] = useState({
    orderNumber: '',
    orderType: '',
    shortJobDescription: '',
    detailedJobDescription: '',
    area: '',
    planFacility: '',
    equipment: '',
    subEquipment: '',
    deviceInstrument: '',
    tag: '',
    jobPriority: '',
    createdBy: '',
    reportedBy: '',
    assignedTo: '',
    orderFrom: null,
    orderTo: null
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date
    });
  };
  const handleSubmitForm = () => {
    console.log(formData);
  }
  const handleClearForm = () => {
    setFormData({
      orderNumber: '',
      orderType: '',
      shortJobDescription: '',
      detailedJobDescription: '',
      area: '',
      planFacility: '',
      equipment: '',
      subEquipment: '',
      deviceInstrument: '',
      tag: '',
      jobPriority: '',
      createdBy: '',
      reportedBy: '',
      assignedTo: '',
      orderFrom: null,
      orderTo: null
    });
  };
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
   
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

   
  `,
  );

  
    const [fields, setFields] = useState([{ value: '' }]);
  
    

  return (
    <Box className="w-full">
      <Grid className='bg-slate-100 '>
        <from>
          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200'>Order Details</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3 mms-date-field'>

              <TextField size='small' label="Order Number" variant="outlined"
                name="orderNumber"
                value={formData.orderNumber}
                onChange={handleInputChange} />

              <TextField size='small' label="Order Type" variant="outlined"
                name="orderType"
                value={formData.orderType}
                onChange={handleInputChange} />

              <TextField size='small' label="Short Job Description" variant="outlined"
                name="shortJobDescription"
                value={formData.shortJobDescription}
                onChange={handleInputChange} />



<FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="planFacility">Order Form</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  value={formData.planFacility}
                  label="OrderForm"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="planFacility">Order To</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  value={formData.planFacility}
                  label="orderto"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              <Textarea aria-label="minimum height" minRows={3} placeholder="Detailed Job Description" />

            </div>
          </div>
          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200'>Assets</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="planFacility">Plan / Facility</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  value={formData.planFacility}
                  label="Plan / Facility"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="equipment">Equipment</InputLabel>
                <Select className='mms-select'
                  name="equipment"
                  value={formData.equipment}
                  label="Equipment"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="subEquipment">Sub -Equipment</InputLabel>
                <Select className='mms-select'
                  name="subEquipment"
                  value={formData.subEquipment}
                  label="Sub -Equipment"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="deviceInstrument">Device / Instrument</InputLabel>
                <Select className='mms-select'
                  name="deviceInstrument"
                  value={formData.deviceInstrument}
                  label="Device / Instrument"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="Tag">Tag</InputLabel>
                <Select className='mms-select'
                  name="tag"
                  value={formData.tag}
                  label="Tag"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

            </div>
          </div>
          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200'>User Details</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>
              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="jobPriority">Job Priority</InputLabel>
                <Select className='mms-select'
                  name="jobPriority"
                  value={formData.jobPriority}
                  label="Job Priority"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="area">Job Priority</InputLabel>
                <Select className='mms-select'
                  name="area"
                  value={formData.area}
                  label="Job Priority"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="createdBy">Created By</InputLabel>
                <Select className='mms-select'
                  name="createdBy"
                  value={formData.createdBy}
                  label="Created By"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="reportedBy">Reported By</InputLabel>
                <Select className='mms-select'
                  name="reportedBy"
                  value={formData.reportedBy}
                  label="Reported By"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="AssignedTo">Assigned To</InputLabel>
                <Select className='mms-select'
                  name="assignedTo"
                  value={formData.assignedTo}
                  label="Assigned To"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

            </div>
          </div>


          


          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200'>EDIT & CLOSE WORK ORDER</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>
              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="jobPermit">Job Permit</InputLabel>
                <Select className='mms-select'
                  name="jobPermit"
                  value={formData.jobPermit}
                  label="Job Permit"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>High</MenuItem>
                  <MenuItem value={20}>Medium</MenuItem>
                  <MenuItem value={30}>Low</MenuItem>
                </Select>
              </FormControl>

              <TextField size='small' label="Job Permit No" variant="outlined"
                name="jobpermitno"
                value={formData.jobpermitno}
                onChange={handleInputChange} />
              <TextField size='small' label="Start" variant="outlined"
                name="start"
                value={formData.start}
                onChange={handleInputChange} />
              <TextField size='small' label="End" variant="outlined"
                name="End"
                value={formData.end}
                onChange={handleInputChange} />

              <TextField size='small' label="Total Duration" variant="outlined"
                name="TotalDuration"
                value={formData.TotalDuration}
                onChange={handleInputChange} />

<TextField size='small' label="Break Down Start" variant="outlined"
                name="BreakDownStart"
                value={formData.BreakDownStart}
                onChange={handleInputChange} />

<TextField size='small' label="Break Down End" variant="outlined"
                name="BreakDownEnd"
                value={formData.BreakDownEnd}
                onChange={handleInputChange} />
<TextField size='small' label="Total Break Down Duration" variant="outlined"
                name="BreakDownDuration"
                value={formData.BreakDownDuration}
                onChange={handleInputChange} />
<TextField size='small' label="Vendor / Contractor" variant="outlined"
                name="VendorContractor"
                value={formData.VendorContractor}
                onChange={handleInputChange} />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Root Case / Analysis" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Action Taken" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Material Consumed" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Special Tools Used" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Safety Precaution Taken" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Special Notes" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Remarks" />
            </div>
          </div>
          <div className='mt-5 flex justify-center'>
            <div className=''>
              <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
            </div>
            <div className='mx-1'>
              <Button variant="contained" className='mx-1.5' >
                Add Similar
              </Button>
            </div>
            <div>
              <Button variant="contained" className='bg-amber-500' onClick={handleClearForm}>
                Clear
              </Button>
            </div>

          </div>
        </from>
      </Grid>
    </Box>
  );
}