import React from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
export default function Forms() {
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };


  const [formData, setFormData] = useState({
    slno: '',
    dateofpurchase: null,
    make: '',
    poNumber: '',
    invoiceNumber: '',
    date: '',
    warrentystart: '',
    warrentyend: '',
    warrentyPeriod: '',
    expiryNotification: '',
    vendorSupplier: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    dateinvoicenum: null,
    dateinvoice: null
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date
    });
  };
  const handleSubmitForm = () => {
    console.log(formData);
  }
  const handleClearForm = () => {
    setFormData({
      slno: '',
      dateofpurchase: null,
      make: '',
      poNumber: '',
      invoiceNumber: '',
      date: '',
      warrentystart: '',
      warrentyend: '',
      warrentyPeriod: '',
      expiryNotification: '',
      vendorSupplier: '',
      contactName: '',
      contactNumber: '',
      contactEmail: '',
      dateinvoice: null,
      dateinvoicenum: null,
    });
  };


  
  const [fields, setFields] = useState([{ value: '' }]);
  
  const handleAddField = () => {
    setFields([...fields, { value: '' }]);
  };

  const handleChangeAdd = (index, event) => {
    const newFields = [...fields];
    newFields[index].value = event.target.value;
    setFields(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form data:', fields);
  };


  return (
    <Box className="w-full">
      <Grid className='bg-slate-100 '>
        <form>
          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200 '>Task Details</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3 mms-date-field'>
              
                <TextField size='small' label="Task Name" variant="outlined"
                  name="slno"
                  value={formData.slno}
                  onChange={handleInputChange} />

              <TextField size='small' label="Task Number" variant="outlined"
                  name="slno"
                  value={formData.slno}
                  onChange={handleInputChange} />
             
             <LocalizationProvider dateAdapter={AdapterDayjs} className="mms-date-field">
                  <DatePicker
                    label="Task Date"
                    value={formData.dateofpurchase}
                    onChange={(date) => handleDateChange(date, 'dateofpurchase')}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <TextField size='small' label="Action" variant="outlined"
                  name="slno"
                  value={formData.slno}
                  onChange={handleInputChange} />
                    
                    <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="planFacility">Status</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  value={formData.planFacility}
                  label="OrderForm"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>

          </div>
        
        
        

          <div className='mt-5 flex justify-center'>
            <div className=''>
              <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
            </div>
            <div className='mx-1'>
              <Button variant="contained" className='mx-1.5' >
                Add Similar
              </Button>
            </div>
            <div>
              <Button variant="contained" className='bg-amber-500' onClick={handleClearForm}>
                Clear
              </Button>
            </div>

          </div>
        </form>
      </Grid>
    </Box>
  );
}