import React from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
export default function Forms() {
    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [formData, setFormData] = useState({
        orderNumber: '',
        orderType: '',
        shortJobDescription: '',
        detailedJobDescription: '',
        area: '',
        planFacility: '',
        equipment: '',
        subEquipment: '',
        deviceInstrument: '',
        tag: '',
        jobPriority: '',
        createdBy: '',
        reportedBy: '',
        assignedTo: '',
        orderFrom: null,
        orderTo: null
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleDateChange = (date, field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };
    const handleSubmitForm = () => {
        console.log(formData);
    }
    const handleClearForm = () => {
        setFormData({
            orderNumber: '',
            orderType: '',
            shortJobDescription: '',
            detailedJobDescription: '',
            area: '',
            planFacility: '',
            equipment: '',
            subEquipment: '',
            deviceInstrument: '',
            tag: '',
            jobPriority: '',
            createdBy: '',
            reportedBy: '',
            assignedTo: '',
            orderFrom: null,
            orderTo: null
        });
    };

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };
    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
    box-sizing: border-box;
   
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

   
  `,
    );

    return (
        <Box className="w-full">
            <Grid className='bg-slate-100 '>
                <from>
                    <div className='text-left bg-white  m-2 border-solid border rounded'>
                        <div className='border-slate-300 rounded-md  '>
                            <h3 className='border-b-2  p-3 bg-gray-200'>Order Details</h3>
                        </div>
                        <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3 mms-date-field'>

                            <TextField size='small' label="S.NO" variant="outlined"
                                name="sno"
                                value={formData.sno}
                                onChange={handleInputChange} />

                            <TextField size='small' label="Equipment" variant="outlined"
                                name="Equipment"
                                value={formData.equipment}
                                onChange={handleInputChange} />

                            <TextField size='small' label="Device/Inst Tag" variant="outlined"
                                name="deviceInstTag"
                                value={formData.deviceInstTag}
                                onChange={handleInputChange} />



                            <TextField size='small' label="Material Description " variant="outlined"
                                name="materialDescription "
                                value={formData.materialDescription}
                                onChange={handleInputChange} />

                          
                            <TextField size='small' label="SAP/ERP code" variant="outlined"
                                name="SAP/ERP code"
                                value={formData.SAPERPcode}
                                onChange={handleInputChange} />
                            <TextField size='small' label="SAP/ERP Description " variant="outlined"
                                name="saperpPDescription"
                                value={formData.saperpPDescription}
                                onChange={handleInputChange} />

                        </div>
                    </div>
                    <div className='text-left bg-white  m-2 border-solid border rounded'>
                        <div className='border-slate-300 rounded-md  '>
                            <h3 className='border-b-2  p-3 bg-gray-200'>Assets</h3>
                        </div>
                        <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>

                            <TextField size='small' label="Make" variant="outlined"
                                name="make"
                                value={formData.make}
                                onChange={handleInputChange} />
                            <TextField size='small' label="Model" variant="outlined"
                                name="model"
                                value={formData.model}
                                onChange={handleInputChange} />
                            <TextField size='small' label="Part No" variant="outlined"
                                name="partno"
                                value={formData.partno}
                                onChange={handleInputChange} />

                            <TextField size='small' label="Qty" variant="outlined"
                                name="qty"
                                value={formData.qty}
                                onChange={handleInputChange} />

                            <TextField size='small' label="UOM" variant="outlined"
                                name="uom"
                                value={formData.uom}
                                onChange={handleInputChange} />

                            <TextField size='small' label="Available Qty" variant="outlined"
                                name="availableqty"
                                value={formData.availableqty}
                                onChange={handleInputChange} />

                            <TextField size='small' label="ROL Qty " variant="outlined"
                                name="rolqty "
                                value={formData.rolqty}
                                onChange={handleInputChange} />

                            <TextField size='small' label="Storage Location " variant="outlined"
                                name="storageLocation"
                                value={formData.storageLocation}
                                onChange={handleInputChange} />
                            <TextField size='small' label="Bin" variant="outlined"
                                name="bin "
                                value={formData.bin}
                                onChange={handleInputChange} />

                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload Image
                                <VisuallyHiddenInput type="file" />
                            </Button>


                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Lead Time"
                                    value={formData.orderFrom}
                                    onChange={(date) => handleDateChange(date, 'leadtime')}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>


                            <TextField size='small' label="EOL" variant="outlined"
                                name="eol "
                                value={formData.eol}
                                onChange={handleInputChange} />

                        </div>
                    </div>


                    <div className='mt-5 flex justify-center'>
                        <div className=''>
                            <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
                        </div>
                        <div className='mx-1'>
                            <Button variant="contained" className='mx-1.5' >
                                Add Similar
                            </Button>
                        </div>
                        <div>
                            <Button variant="contained" className='bg-amber-500' onClick={handleClearForm}>
                                Clear
                            </Button>
                        </div>

                    </div>
                </from>
            </Grid>
        </Box>
    );
}