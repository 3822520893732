import React from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import  { useState } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
export default function Forms() {
    const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [formData, setFormData] = useState({
    pmno: '',
    area: '',
    facility: '',
    equipment: '',
    subequipment: '',
    device: '',
    instrumenttag: '',
    jobnature: '',
    lastperformed: '',
    frequency: '',
    tolerence: '',
    repeat: '',
    advancenotification: '',
    status: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
const handleSubmitForm = () => {
  console.log(formData);
}
  const handleClearForm = () => {
    setFormData({
        pmno: '',
        area: '',
        facility: '',
        equipment: '',
        subequipment: '',
        device: '',
        instrumenttag: '',
        jobnature: '',
        lastperformed: '',
        frequency: '',
        tolerence: '',
        repeat: '',
        advancenotification: '',
        status: ''
    });
  };
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
   
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

   
  `,
  );
return (
<Box className="w-full">
<Grid className='bg-slate-100 '>
<form>
<div className='text-left bg-white m-2 border-solid border rounded'>
<div className='border-slate-300 rounded-md  '>
    <h3 className='border-b-2  p-3 bg-gray-200'>Assets</h3>
  </div>
  <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>
   
      <TextField size='small' type="text" label="PM/No" variant="outlined" 
          name="pmno"
          value={formData.pmno}
          onChange={handleInputChange} />
    
    <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="area">Area</InputLabel>
        <Select className='mms-select'
          name="area"
          value={formData.area}
          label="Area"
          onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
   
    <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="area">Facility</InputLabel>
        <Select className='mms-select'
          name="facility"
          value={formData.facility}
          label="Facility"
          onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    
    
    <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="area">Equipment</InputLabel>
        <Select className='mms-select'
          name="equipment"
          value={formData.equipment}
          label="Equipment"
          onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    
    
    <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="area">Sub Equipment</InputLabel>
        <Select className='mms-select'
          name="subequipment"
          value={formData.subequipment}
          label="Sub Equipment"
          onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    
    <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="area">Device</InputLabel>
        <Select className='mms-select'
          name="device"
          value={formData.device}
          label="Device"
          onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    
  </div>
</div>
<div className='text-left bg-white m-2 border-solid border rounded'>
  <div className='border-slate-300 rounded-md  '>
    <h3 className='border-b-2  p-3 bg-gray-200'>Assets</h3>
  </div>
  <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>
  
    <TextField size='small' label="Instrument Tag" variant="outlined"  
    name="instrumenttag"
    value={formData.instrumenttag}
    onChange={handleInputChange} />
    
    <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="plan">Job Nature</InputLabel>
        <Select className='mms-select'
        name="jobnature"
        value={formData.jobnature}
        label="Job Nature"
        onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
   
    <TextField size='small' label="Last Performed" variant="outlined"
    name="lastperformed"
    value={formData.lastperformed}
    onChange={handleInputChange} />
    
      <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="plan">Frequency</InputLabel>
        <Select className='mms-select'
          name="frequency"
          value={formData.frequency}
          label="Frequency"
          onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    
    <TextField size='small' label="Tolerence" variant="outlined"  
    name="tolerence"
    value={formData.tolerence}
    onChange={handleInputChange} />
   
    
      <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="plan">Repeat</InputLabel>
        <Select className='mms-select'
         name="repeat"
         value={formData.repeat}
         label="Repeat"
         onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
   
 
    
   
    <TextField size='small' label="Advance Notification" variant="outlined"
    name="advancenotification"
    value={formData.advancenotification}
    onChange={handleInputChange} />
    
      <FormControl sx={{ minWidth: 207 }}>
        <InputLabel id="area">Status </InputLabel>
        <Select className='mms-select'
         name="status"
         value={formData.status}
         label="Status"
         onChange={handleInputChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
   
  </div>
</div>
<div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200'>EDIT PM SI</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>
            <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="jobPermit">Job Perm</InputLabel>
                <Select className='mms-select'
                  name="jobPermit"
                  value={formData.jobPermit}
                  label="Job Permit"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>High</MenuItem>
                  <MenuItem value={20}>Medium</MenuItem>
                  <MenuItem value={30}>Low</MenuItem>
                </Select>
              </FormControl>
              <TextField size='small' label="Start" variant="outlined"
                name="start"
                value={formData.start}
                onChange={handleInputChange} />
              <TextField size='small' label="End" variant="outlined"
                name="End"
                value={formData.end}
                onChange={handleInputChange} />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Job Status" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Action Taken" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Material Consumed" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Special Tools Used" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Safety Precaution Taken" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Special Notes" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Remarks" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Check List" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Attended On" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Attended By" />
            </div>
          </div>
<div className='mt-5 flex justify-center'>
  <div className=''>
    <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
  </div>
  <div className='mx-1'>
    <Button variant="contained" className='mx-1.5' >
      Add Similar
    </Button>
  </div>
  <div>
    <Button variant="contained" type="button" className='bg-amber-500' onClick={handleClearForm}>
      Clear
    </Button>
  </div>

</div>
</form>
</Grid>
</Box>
);
}