
import { baseUrl } from "../../constants";

export default {
    "formTitle": " Sub Equipment",
    "tableTitle": "Sub Equipment List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/ship/shipclass/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/ship/shipclass`,
        edit: `${baseUrl}/api/ship/shipclass/edit`,
        delete: `${baseUrl}/api/ship/shipclass/remove`
    },
    "tableCol": [
        {
            name: 'Department',
            selector: row => row.department,
            sortable: true
        },
        {
            name: 'Area',
            selector: row => row.area,
            sortable: true
        },
        {
            name: 'Facility',
            selector: row => row.facility,
            sortable: true
        },
        {
            name: 'Equipment',
            selector: row => row.equipment,
            sortable: true
        },
        {
            name: 'Sub Equipment',
            selector: row => row.subEquipment,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department',
                    "options": [
                        "Agency",
                        "Logistics"
                    ],
                    "required": true
                },
                {
                    type: 'select',
                    label: 'Area',
                    name: 'area',
                    "options": [
                        "Ship",
                        "Shore"
                    ],
                    "required": true
                },
                {
                    type: 'select',
                    label: 'Facility',
                    name: 'facility',
                    "options": [
                        "Tester",
                        "Crew"
                    ],
                    "required": true
                },
                {
                    type: 'select',
                    label: 'Equipment',
                    name: 'equipment',
                    "options": [
                        "Anchor",
                        "Hull"
                    ],
                    "required": true
                },
                {
                    type: 'text',
                    label: 'Sub Equipment',
                    name: 'subEquipment',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "department": 'Logistics',
        "area": 'Ship',
        "facility": 'Tester',
        "equipment": 'Anchor',
        "subEquipment": "Chain"
    },{
        "department": 'Agency',
        "area": 'Shore',
        "facility": 'Crew',
        "equipment": 'Hull',
        "subEquipment": "Compass"
    },{
        "department": 'Logistics',
        "area": 'Ship',
        "facility": 'Tester',
        "equipment": 'Anchor',
        "subEquipment": "Chain"
    },{
        "department": 'Agency',
        "area": 'Shore',
        "facility": 'Crew',
        "equipment": 'Hull',
        "subEquipment": "Compass"
    }]
}
