import React, {
    useState,
    useEffect
} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

import { getDropDown, getUserProfile } from '../../redux/selector';
import { setApiAlert } from '../../redux/actions/common';

import formData from '../../formData';
import { fieldNameMapper, jobToPdaMap } from '../../utils';
import MultiListForm from '../multiListForm/multiListForm';
import { getJobDetails } from '../../services';
import './styles.css';

const textType = ['text', 'password', 'email', 'number', 'file', 'textArea', 'richText', 'date'];
const serviceMenus = ['pda', 'appointments'];
const switchValMapper = (switchVal) => {
    if (typeof switchVal === 'string') {
        return switchVal === 'Yes'
    }
    else if (typeof switchVal === 'number') {
        return switchVal === 1;
    }
    return switchVal;
}
const SwitchComp = ({
    handleFieldChange,
    defaultValue,
    label,
    required
}) => {
    const [switchVal, setSwitchVal] = useState(switchValMapper(defaultValue));

    const handleChange = (event) => {
        setSwitchVal(event.target.checked);
        handleFieldChange(event, undefined, 'switch')
    }
    return <FormControlLabel required={required} control={<Switch
        checked={switchVal}
        onChange={handleChange}
    />} label={label} />
};

const RichTextEditor = ({
    handleFieldChange,
    defaultValue,
    label,
    required
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (data) => {
        setEditorState(data);
        handleFieldChange({ target: convertFromRaw(data) });
    };

    return <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
    />;
};

const FormBuilder = ({
    defaultFieldValue,
    isEdit = false,
    isAdd = false,
    resetForm,
    menuName,
    callFetchList
}) => {
    const dispatch = useDispatch();
    const profileData = useSelector(getUserProfile);
    const dropDownState = useSelector(getDropDown);
    const [dropDownVal, setDropDownVal] = useState(dropDownState || {});
    const [keyVal, setKeyVal] = useState(uuidv4());

    useEffect(() => {
        setDropDownVal(dropDownState || {});
    }, [dropDownState]);

    const [dataJson, setDataJson] = useState(formData[menuName].formData);
    const [hasError, setHasError] = useState(false);
    const [resultData, setResultData] = useState(() => {
        const result = {};
        formData[menuName].formData.forEach(({ formFields, formType, formName }, i) => {
            if (formType === 'list') {
                result[formName] = defaultFieldValue[formName] ?? [];
            }
            else {
                formFields.forEach(({ name }, j) => {    
                    result[name] = defaultFieldValue[name] === 'null' ? null : (defaultFieldValue[name] ?? null);
                });
            }
        });
        return result;
    });
    const fieldToDefaultToday = ['requestTimestamp', 'pdaDate']

    useEffect(() => {
        let modifiedJson = formData[menuName].formData;
        if( serviceMenus.includes(menuName) && defaultFieldValue['services']?.length){
            const servForm = [];
            let fIndex = 0;
            modifiedJson.forEach(({ formType, formIndex, addForm })=>{
                if( formType === 'addList' ){
                    servForm.push(...(defaultFieldValue['services'].map(({ operation })=>{
                        return {
                            ...addForm,
                            formTitle: `Operation - ${operation?.name}`,
                            formName: `Operation-${operation?.name}`
                        }
                    })));
                    fIndex = formIndex;
                }
            });
            modifiedJson = [
                ...modifiedJson.slice(0, fIndex),
                ...servForm,
                ...modifiedJson.slice(fIndex)
            ];
        }
        setResultData(() => {
            const result = {};
            formData[menuName].formData.forEach(({ formFields, formType, formName }, i) => {
                if (formType === 'list') {
                    result[formName] = defaultFieldValue[formName] ?? [];
                }
                else {
                    formFields.forEach(({ name }, j) => {
                        let resultVal = defaultFieldValue[name] ?? null;
                        resultVal = fieldToDefaultToday.includes(name) ?
                            dayjs(new Date()).format('YYYY-MM-DD h:mm:ss') : resultVal;
                        result[name] = resultVal === 'null' ? null : resultVal;              
                        // if( name.includes('jobNum') ){
                        //     console.log(defaultFieldValue[name]);
                        // }
                    });

                    if( formType === 'addList' && 
                        serviceMenus.includes(menuName) && 
                        defaultFieldValue['services']?.length 
                    ){
                        defaultFieldValue['services'].forEach((item)=>{
                            const { operation } = item || {};
                            result[`Operation-${operation?.name}`] = result[`Operation-${operation?.name}`]?.length ?
                            [...result[`Operation-${operation?.name}`], item]
                            : [item];
                        });
                    }
                }
            });
            // console.log('result --- ', result)
            return result;
        });
        setDataJson(modifiedJson);
        setKeyVal(uuidv4());
    }, [menuName, defaultFieldValue?.id]);

    const addToList = (payload) => {
        const ignoreId = ['pda', 'dsr', 'jobs', 'clientReviews', 'appointments'];
        // console.log(payload);
        // return;
        const { add, edit } = formData[menuName]?.apiDetails || {};
        const url = !isAdd ? edit : add;
        const userBy = !isAdd ? 'edit_by' : 'add_by';
        const apiData = {
            ...payload,
            [userBy]: profileData?.id || 1
        };
        if(!ignoreId.includes(menuName)){
            apiData.id = defaultFieldValue?.id || 0;
        } 
        if (typeof apiData.services === 'object' && apiData.services) {
            apiData.services = JSON.stringify(apiData.services);
        }
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(apiData)) {
            let fieldVal = value;
            let ignoreValue = false;
            formData[menuName].formData.forEach(({ formFields }) => {
                formFields.forEach(({ name, type, fieldIgnore }) => {
                    if (key === name) {
                        ignoreValue = fieldIgnore;
                    }
                    if (key === name && type === 'switch') {
                        fieldVal = switchValMapper(value) ? 1 : 0;
                    }
                    else if (key === name && type === 'select') {
                        fieldVal = value || 0;
                        fieldVal = typeof value === 'string' ? (defaultFieldValue?.[`${key}_DD`]?.id || 0) : fieldVal;
                    }
                    else if (key === name && type === 'number') {
                        fieldVal = typeof value === 'number' ? value || 0 : 0;
                    }
                });
            });
            if (!ignoreValue) {
                formDataObj.append(key, fieldVal);
            }
        }
        fetch(url, {
            method: "POST",
            body: formDataObj
            // body: JSON.stringify(apiData),
            // headers: {
            //     "Content-type": "application/json; charset=UTF-8"
            // }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("NETWORK RESPONSE ERROR");
                }
            })
            .then(data => {
                console.log(data);
                dispatch(setApiAlert({
                    error: data?.data?.error,
                    msg: data?.message
                }));
                if (!data?.data?.error) {
                    if (isAdd) {
                        const result = {};
                        formData[menuName].formData.forEach(({ formFields, formType, formName }, i) => {
                            if (formType === 'list') {
                                result[formName] = [];
                            }
                            else {
                                formFields.forEach(({ name, type }, j) => {
                                    let resultVal = textType.includes(type) ? '' : null;
                                    resultVal = fieldToDefaultToday.includes(name) ?
                                        dayjs(new Date()).format('YYYY-MM-DD h:mm:ss') : resultVal;
                                    result[name] = resultVal === 'null' ? null : resultVal;
                                });
                            }
                        });
                        setResultData(result);
                    }
                    resetForm();
                }
                // document.getElementById("output").innerText = JSON.stringify(data, undefined, 2);
            })
            .catch((error) => {
                console.error("FETCH ERROR:", error)
            });
    }

    const createFields = (fieldData, stateData, {
        formType,
        formName,
        index,
        addForm,
        formIndex
    } = {}) => {
        if (!isEdit) { textType.push('textArea'); }
        return fieldData.map(({ type, label,
            required, options, hideOnEdit = false,
            fieldIgnore,
            name, fieldProps = {} }, j) => {
            let defaultValue = formType === 'list' ?
                (defaultFieldValue?.[`${formName}_DD`]?.[index]?.[name] ?? null)
                : (defaultFieldValue?.[name] ?? null);
            defaultValue = defaultValue === 'null' ? null : defaultValue;
            const viewStyle = isEdit ? {} : {
                inputProps: { readOnly: true },
                sx: {
                    "&:has([readonly]) ": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                        },
                    },
                }
            };

            const handleFieldChange = async (e, selectVal, type, uName, {
                fieldIgnore
            } = {}) => {
                let fieldVal = e.target.value;
                let jobPda = {};
                if (type === 'file') {
                    // fieldVal = e.target.value;
                    fieldVal = e.target.files[0];
                }
                else if (type === 'switch') {
                    fieldVal = e.target.checked ? 1 : 0;
                }
                else if (type === 'selectOption') {
                    fieldVal = fieldIgnore ? selectVal : selectVal?.id;
                    if (uName === 'jobNum') {
                        const jobData = await getJobDetails({ job_id: fieldVal });
                        jobPda = jobToPdaMap(jobData);
                        console.log(jobData, jobPda);
                    }
                }

                if (formType === 'list') {
                    const updatedList = stateData[formName];
                    if (!updatedList[index]) {
                        updatedList.length = index + 1;
                        updatedList[index] = {};
                    }
                    updatedList[index][name] = fieldVal;
                    if( serviceMenus.includes(menuName) && 
                        (name === 'quantity' || name === 'prefCcy')
                    ){
                        console.log(updatedList[index]['quantity'] * updatedList[index]['prefCcy']);
                        updatedList[index]['amount'] = (updatedList[index]['quantity'] * updatedList[index]['prefCcy']) || 0;
                    }
                    setResultData({ ...stateData, [formName]: updatedList });
                }
                else {
                    setResultData({
                        ...stateData,
                        [name]: fieldVal || (type === 'switch' ? 0 : selectVal?.id),
                        ...jobPda
                    });
                }
            };

            let textAreaProps = {}
            if (type === 'textArea' || type === 'richText') {
                textAreaProps = {
                    fullWidth: true,
                    multiline: true,
                    rows: 2,
                    maxRows: 4
                }
            }
            if (hideOnEdit && !isAdd) {
                return null;
            }
            else if (textType.includes(type)) {
                let fieldVal = { value: formType === 'list' ? resultData?.[formName]?.[index]?.[name] : resultData?.[name] };
                if (type === 'file') {
                    fieldVal = {};
                }
                return <TextField
                    key={`${keyVal}-${name}`}
                    label={label}
                    required={required}
                    InputLabelProps={{ shrink: true }}
                    type={isEdit ? type : 'text'}
                    onChange={(e) => {
                        handleFieldChange(e, undefined, type)
                    }}
                    // inputProps={{ style: { resize: 'both' }, }}
                    defaultValue={defaultValue}
                    {...fieldVal}
                    {...viewStyle}
                    {...fieldProps}
                    {...textAreaProps}
                />;
            }
            else if (type === 'switch') {
                return <SwitchComp
                    key={`${keyVal}-${name}`}
                    defaultValue={defaultValue}
                    handleFieldChange={handleFieldChange}
                    label={label}
                    required={required}
                />
            }
            else if (type === 'button') {
                return <button className="btn"
                    style={{
                        height: '40px',
                        marginTop: '10px'
                    }}
                    onClick={() => {
                        const oppVal = resultData?.operation;
                        if (resultData.operation && !resultData[resultData.operation]) {
                            setResultData({
                                ...resultData,
                                [`Operation-${oppVal?.label}`]: []
                            });
                            setDataJson([
                                ...dataJson.slice(0, formIndex),
                                {
                                    ...addForm,
                                    formTitle: `Operation - ${oppVal?.label}`,
                                    formName: `Operation-${oppVal?.label}`
                                },
                                ...dataJson.slice(formIndex)
                            ])
                        }
                        // console.log(resultData);
                    }}>{label}</button>
            }
            else if (type === 'datetime') {
                let fieldVal = { value: dayjs(resultData?.[name]) };
                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            key={`${keyVal}-${name}`}
                            defaultValue={dayjs(defaultValue)}
                            onChange={(newValue) => {
                                const dateFormatedVal = dayjs(newValue).format('YYYY-MM-DD h:mm:ss');
                                handleFieldChange({
                                    target: {
                                        value: dateFormatedVal
                                        // value: newValue.replace('T', ' ').replace('.000Z', '') 
                                    }
                                })
                            }}
                            {...fieldVal}
                            label={label}
                            required={required}
                            slotProps={{
                                textField: {
                                    error: false,
                                },
                            }}
                        />
                    </LocalizationProvider>
                )
            }
            // else if (type === 'richText') {
            //     return <RichTextEditor
            //         defaultValue={defaultValue}
            //         handleFieldChange={handleFieldChange}
            //         label={label}
            //         required={required}
            //     />
            // }
            // else if () {
            //     return <TextField
            //         InputLabelProps={{ shrink: true }}
            //         defaultValue={defaultValue}
            //         onChange={handleFieldChange}
            //         label={label}
            //         required={required}
            //     />
            // }
            else if (type === 'select') {
                const fName = fieldNameMapper(name, menuName);
                const optionsVal = dropDownVal?.[fName] || options || [];
                const optionValue = optionsVal.map((optData, index) => {
                    if (typeof optData === 'string') {
                        return { id: index, label: optData };
                    }
                    else {
                        return { id: optData.id || (index + 1), label: optData?.[name] || optData?.name }
                    }
                })

                const dataSelectVal = formType === 'list' ? (resultData?.[formName]?.[index]?.[name]?.id || resultData?.[formName]?.[index]?.[name]) : resultData?.[name];
                const selectVal = dataSelectVal ? optionValue.filter((data) => {
                    if (typeof dataSelectVal === 'object') {
                        return data.id === dataSelectVal.id;
                    }
                    return typeof dataSelectVal === 'string' ? data.label === dataSelectVal : data.id === dataSelectVal
                })[0] : null;
                const defaultSelectVal = defaultValue ? optionValue.filter((data) => {
                    if (typeof defaultValue === 'object') {
                        return data.id === defaultSelectVal.id;
                    }
                    return typeof defaultValue === 'string' ? data.label === defaultValue : data.id === defaultValue
                })[0] : null;
                return <Autocomplete
                    key={`${keyVal}-${name}`}
                    sx={{
                        'width': 200
                    }}
                    defaultValue={defaultSelectVal?.label}
                    disablePortal
                    options={optionValue}
                    onChange={(e, selectVal, type) => {
                        handleFieldChange(e, selectVal, type, name, {
                            fieldIgnore
                        })
                    }}
                    value={selectVal}
                    renderInput={(params) => <TextField
                        {...params}
                        label={label}
                    />}
                    {...fieldProps}
                />;
            }
            return '';
        })
    }
    const createForm = (jsonData) => {
        const stackProps = {
            spacing: { xs: 1, sm: 2, md: 3 },
            sx: {
                margin: '20px',
                pointerEvents: isEdit ? 'auto' : 'none'
            },
            direction: "row",
            useFlexGap: true,
            flexWrap: "wrap"
        };
        return jsonData.map((item, i) => {
            const {
                formType,
                formFields,
                formTitle,
                addForm,
                formIndex
            } = item;
            const boxProps = {
                sx: {
                    marginBottom: '20px',
                    padding: '20px 0',
                    borderRadius: '6px',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.33)'
                }
            };
            const titleStyles = {
                textAlign: 'left',
                marginLeft: '20px',
                fontSize: '18px',
                fontWeight: 'bold'
            };
            if (formType === 'flex') {
                return <Stack {...stackProps}>{
                    createFields(formFields, resultData)
                }</Stack>;
            }
            else if (formType === 'box') {
                return <Box {...boxProps}>
                    <div
                        class="box-title"
                        style={titleStyles}
                    >{formTitle}</div>
                    <Stack {...stackProps}>
                        {createFields(formFields, resultData)}
                    </Stack>
                </Box>;
            }
            else if (formType === 'list') {
                let defaultVal = typeof defaultFieldValue[`${item.formName}_DD`] === 'object' ? defaultFieldValue[`${item.formName}_DD`] : [];
                if ((menuName === 'pda' || menuName === 'appointments') && !defaultVal?.length) {
                    defaultVal = typeof resultData[item.formName] === 'object' ? resultData[item.formName] : [];
                }
                const multiListFormProps = {
                    ...item,
                    isEdit,
                    defaultFieldValue: defaultVal,
                    boxProps,
                    stackProps,
                    titleStyles,
                    createFields,
                    resultData,
                    i,
                    menuName
                }
                return <MultiListForm {...multiListFormProps} />;
            }
            else if (formType === 'addList') {
                return <Stack {...stackProps}>{
                    createFields(formFields, resultData, {
                        addForm, formIndex
                    })
                }</Stack>;
            }

            return '';
        });
    };

    const handleSubmit = () => {
        const modifiedJson = dataJson;
        let isModified = false;
        let isError = false;
        dataJson.forEach(({ formFields, formType, formName }, i) => {
            formFields.forEach(({ name, label, required, hideOnEdit }, j) => {
                // const fieldVal = formType === 'list' ? 
                // resultData[formName][0]?.[name]
                // : resultData[name];
                if (required && formType !== 'list' && !(hideOnEdit && !isAdd)) {
                    if (!resultData[name]) {
                        isError = isError ? isError + ', ' + label : label;
                        isModified = true;
                        modifiedJson[i].formFields[j]['fieldProps'] = {
                            ...modifiedJson[i].formFields[j].fieldProps,
                            error: true,
                            helperText: "Field mandatory"
                        }
                    }
                    else if (modifiedJson[i].formFields[j]?.fieldProps?.error) {
                        isModified = true;
                        modifiedJson[i].formFields[j]['fieldProps'] = {
                            ...modifiedJson[i].formFields[j].fieldProps,
                            error: false,
                            helperText: ""
                        }
                    }
                }
            });
        });
        if (isModified) {
            setDataJson(modifiedJson);
        }
        setHasError(isError);
        if (!isError) {
            const newData = {};
            for (const [key, value] of Object.entries(resultData)) {
                const keyVal = key.split('-');
                if (typeof value === 'object' && value) {
                    if (keyVal[0] === "Operation") {
                        const valArr = value.map(item => ({
                            ...item,
                            operation: dropDownVal?.['operation']?.filter?.(({ name }) => {
                                return keyVal[1] === name;
                            })?.[0]?.id
                        }));
                        if (typeof newData['services'] === 'string') {
                            newData['services'] = [];
                        }
                        newData['services'] = newData['services'] ? [
                            ...newData['services'],
                            ...valArr
                        ] : valArr
                    }
                    else {
                        newData[key] = (value?.lastModified ? value : JSON.stringify(value));
                    }
                }
                else {
                    newData[key] = value;
                }
            }
            addToList(newData);
        }
    };

    const handleClose = () => {
        setHasError(false);
    };

    return (<Box sx={{
        padding: '20px'
    }} className="form-builder">
        {hasError ? <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
        >
            {`Error in field - ${hasError}`}
        </Alert> : ''}
        {hasError ? <div style={{ display: 'none' }}> Error </div> : ''}
        {createForm(dataJson)}
        {isEdit ? <Button variant="contained" onClick={handleSubmit}>Submit</Button> : ''}
    </Box>);
}

export default FormBuilder;