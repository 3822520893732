import React, { useMemo } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DateSelect from '../dateSelect/dateSelect';
const data = [
  {
    sno: 1,
    equipment: "Container",
    deviceInstTag: "test",
    materialDescription:"Loran",
    saperCode: "1234",
    saperpPDescription: "Test Message",
    make: "IT",
    model:"Test",
    partno: "loram",
    qty: "124",
    uom: "Testing",
    availableqty:"Loram",
    rolqty: "High",
    storageLocation: "Chennai ",
    bin: "test",
    image:" ",
    leadtime: "12-07-2023 ",
    eol: "test",
  },
  {
    sno: 2,
    equipment: "cargo",
    deviceInstTag: "aaaa",
    materialDescription:"bbbbb",
    saperCode: "9832",
    saperpPDescription: "Test Message",
    make: "ccccc",
    model:"dddddd",
    partno: "8293",
    qty: "9992e",
    uom: "eeeee",
    availableqty:"fffffff",
    rolqty: "gggggggg",
    storageLocation: "Mumbai ",
    bin: "hhhh",
    image:" ",
    leadtime: "30-09-2024 ",
    eol: "iiiiii",
  },

];

export default function Tableworkorder({setOpenDialog}) {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.sno, 
        id: "sno",
        header: "S.No",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.equipment, 
        id: "Equipment",
        header: "Equipment",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.deviceInstTag,
        id: "DeviceInstTag", 
        header: "Device / Inst Tag",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.materialDescription , 
        id: "materialDescription",
        header: "Material Description",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.saperCode, 
        id: "saperCode",
        header: "SAP/ERP code",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.saperpPDescription, 
        id: "saperpPDescription ",
        header: "SAP/ERP Description ",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.make, 
        id: "make",
        header: "Make",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.model,
        id: "model", 
        header: "Model",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.partno, 
        id: "partno",
        header: "Part No",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.qty,
        id: "qty",
        header: "Qty",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.uom, 
        id: "uom",
        header: "UOM",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.availableqty, 
        id: "availableqty",
        header: "Available Qty",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.rolqty, 
        id: "rolqty",
        header: "ROL Qty ",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.storageLocation, 
        id: "storageLocation ",
        header: "Storage Location ",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.bin, 
        id: "bin",
        header: "Bin",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.image, 
        id: "image",
        header: "Image",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.leadtime, 
        id: "leadtime",
        header: "Lead Time",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.eol, 
        id: "eol",
        header: "EOL",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    data,
    columns,
     renderTopToolbarCustomActions : () => (<>
     <Box >
     <Grid className='bg-slate-100 '>
     
      <Button
          variant="contained" color="success"
          onClick={() => {setOpenDialog(true)
          }}
      >+ Add</Button> 
      <Button variant="contained" className='bg-amber-500 mx-0.5 mlr-5' >
      Export
    </Button>
    <Button variant="contained" className='bg-violet-500' >
      Import
    </Button>
    <DateSelect></DateSelect>
      </Grid>
      </Box>
  </>),
  });

  return <MaterialReactTable table={table} />;
}
