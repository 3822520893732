import React, { useMemo } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import DateSelect from '../dateSelect/dateSelect';
const data = [
  {
    orderNumber: 93242042,
    orderType: "Container",
    orderFrom: "20-Jun-2024",
    orderTo:"19-Jul-2024",
    shortJobDescription: "Test Message",
    detailedJobDescription: "Test Message",
    area: "IT",
    planFacility:"Test",
    equipment: "loram",
    subEquipment: "Container",
    deviceInstrument: "Testing",
    tag:"Loram",
    jobPriority: "High",
    createdBy: "David ",
    reportedBy: "Sam",
    assignedTo:"Kunal",
  },
  {
    orderNumber: 87302334,
    orderType: "Request",
    orderFrom: "17-May-2024",
    orderTo:"13-Oct-2024",
    shortJobDescription: "Test Message",
    detailedJobDescription: "Test Message",
    area: "Mach",
    planFacility:"Facility",
    equipment: "loram",
    subEquipment: "Container",
    deviceInstrument: "Testing",
    tag:"Loram",
    jobPriority: "High",
    createdBy: "David ",
    reportedBy: "Sam",
    assignedTo:"Kunal",
  },
  {
    orderNumber: 87302334,
    orderType: "Request",
    orderFrom: "17-May-2024",
    orderTo:"13-Oct-2024",
    shortJobDescription: "Test Message",
    detailedJobDescription: "Test Message",
    area: "Mach",
    planFacility:"Facility",
    equipment: "loram",
    subEquipment: "Container",
    deviceInstrument: "Testing",
    tag:"Loram",
    jobPriority: "High",
    createdBy: "David ",
    reportedBy: "Sam",
    assignedTo:"Kunal",
  }
];

export default function Tableworkorder({setOpenDialog}) {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.orderNumber, 
        id: "orderNumber",
        header: "Order Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.orderType, 
        id: "orderType",
        header: "Order Type",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.orderFrom,
        id: "orderFrom", 
        header: "Order From",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.orderTo, 
        id: "orderTo",
        header: "Order To",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.shortJobDescription, 
        id: "shortJobDescription",
        header: "Short Job Description ",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.detailedJobDescription, 
        id: "detailedJobDescription",
        header: "Detailed Job Description",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.area, 
        id: "area",
        header: "Area",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.planFacility,
        id: "planFacility", 
        header: "Plan / Facility",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.equipment, 
        id: "equipment",
        header: "Equipment",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.subEquipment,
        id: "subEquipment",
        header: "Sub -Equipment",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.deviceInstrument, 
        id: "deviceInstrument",
        header: "Device / Instrument",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.tag, 
        id: "tag",
        header: "Tag",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.jobPriority, 
        id: "jobPriority",
        header: "Job Priority",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.createdBy, 
        id: "createdBy",
        header: "Created By",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.reportedBy, 
        id: "reportedBy",
        header: "Reported By",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      },
      {
        accessorFn: (row) => row.assignedTo, 
        id: "assignedTo",
        header: "Assigned To",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      }
    ],
    []
  );
  
  const table = useMaterialReactTable({
    data,
    columns,
     renderTopToolbarCustomActions : () => (<>
     <Box >
     <Grid className='bg-slate-100 '>
     
      <Button
          variant="contained" color="success"
          onClick={() => {setOpenDialog(true)
          }}
      >+ Add</Button> 
      <Button variant="contained" className='bg-amber-500 mx-0.5 mlr-5' >
      Export
    </Button>
    <Button variant="contained" className='bg-violet-500' >
      Import
    </Button>
    <DateSelect></DateSelect>
    
      </Grid>
      </Box>
  </>),
  });

  return <MaterialReactTable table={table} />;
}
