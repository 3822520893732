
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Plan / Facility",
    "tableTitle": "Plan / Facility List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/ship/shipclass/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/ship/shipclass`,
        edit: `${baseUrl}/api/ship/shipclass/edit`,
        delete: `${baseUrl}/api/ship/shipclass/remove`
    },
    "tableCol": [
        {
            name: 'Department',
            selector: row => row.department,
            sortable: true
        },
        {
            name: 'Area',
            selector: row => row.area,
            sortable: true
        },
        {
            name: 'Facility',
            selector: row => row.facility,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department',
                    "options": [
                        "Agency",
                        "logistics"
                    ],
                    "required": true
                },
                {
                    type: 'select',
                    label: 'Area',
                    name: 'area',
                    "options": [
                        "Admin",
                        "Process"
                    ],
                    "required": true
                },
                {
                    type: 'text',
                    label: 'Facility',
                    name: 'facility',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "department": 1,
        "area": 1,
        "facility": "safds"
    }]
}