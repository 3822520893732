
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Job Nature",
    "tableTitle": "Job Nature List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/ship/shipclass/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/ship/shipclass`,
        edit: `${baseUrl}/api/ship/shipclass/edit`,
        delete: `${baseUrl}/api/ship/shipclass/remove`
    },
    "tableCol": [
        {
            name: 'Job Nature',
            selector: row => row.jobNature,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Job Nature',
                    name: 'jobNature',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "jobNature": "dgdg"
    }]
}