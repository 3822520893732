
export default function dropDown(state = {}, { type, payload }) {
    switch (type) {
        case "SET_DD_REGION":
            return { ...state, region: payload };
        case "SET_DD_CCY":
            return { ...state, currency: payload };
        case "SET_DD_COUNTRY":
            return { ...state, country: payload };
        case "SET_DD_AGENCY":
            return { ...state, agency: payload, selectedAgency: payload?.[0]?.id };
        case "SET_DD_ROLE":
            return { ...state, role: payload };
        case "SET_DD_N_JOB":
            return { ...state, jobNature: payload };
        case "SET_DD_UNIT":
            return { ...state, unit: payload };
        case "SET_DD_CLI_GRP":
            return { ...state, clientGroup: payload };
        case "SET_DD_GRP":
            return { ...state, group: payload };
        case "SET_DD_CLI":
            return { ...state, client: payload };
        case "SET_DD_ACT":
            return { ...state, activity: payload };
        case "SET_DD_OPT":
            return { ...state, operation: payload };
        case "SET_DD_CAT":
            return { ...state, category: payload };

        case "SET_DD_SER_TYPE":
            return { ...state, seriesType: payload };
        case "SET_DD_PDA_CCY":
            return { ...state, pdaCcy: payload };
        case "SET_DD_PAY_TERM":
            return { ...state, payTerm: payload };
        case "SET_DD_NUM_FMT":
            return { ...state, numFormat: payload };
        case "SET_DD_BUIS":
            return { ...state, businessNature: payload };
        case "SET_DD_TIMEZ":
            return { ...state, timeZone: payload };
        case "SET_DD_FEATURE":
            return { ...state, feature: payload };
        case "SET_DD_B_DATE":
            return { ...state, baseDate: payload };
        case "SET_DD_AGENT_ROLE":
            return { ...state, agencyRole: payload };
        case "SET_DD_ACC_TYPE":
            return { ...state, accType: payload };
        case "SET_DD_REPORT_EMAIL":
            return { ...state, reportTo: payload };
        case "SET_DD_ALERT_TYPE":
            return { ...state, alertType: payload };
        case "SET_DD_FLAG":
            return { ...state, flag: payload };
        case "SET_DD_SHIP_SUB_TYPE":
            return { ...state, shipSubType: payload };
        case "SET_DD_SHIP_TYPE":
            return { ...state, shipType: payload };
        case "SET_DD_SHIP_CLASS":
            return { ...state, shipClass: payload };
        case "SET_DD_SHIP_ROLE":
            return { ...state, shipRole: payload };
        case "SET_DD_PORT":
            return { ...state, port: payload };
        case "SET_DD_REQ_TYPE":
            return { ...state, requestType: payload };
        case "SET_DD_SHIP":
            return { ...state, ship: payload };

        case "SET_DD_PURPOSE":
            return { ...state, purpose: payload };
        case "SET_DD_GENDER":
            return { ...state, gender: payload };
        case "SET_DD_ARRIVAL":
            return { ...state, arrivalMode: payload };
        case "SET_DD_MAT_TYPE":
            return { ...state, materialType: payload };
        case "SET_DD_MAT_STATUS":
            return { ...state, materialStatus: payload };
        case "SET_DD_JOB_NUM":
            return { ...state, jobNum: payload };
        case "SET_DD_BANK":
            return { ...state, bank: payload };
        case "SET_DD_CLI_ADDR":
            return { ...state, clientAddr: payload };
        case "SET_DD_ACT_UNIT":
            return { ...state, actUnit: payload };
        case "SET_DD_PDA_LIST":
            return { ...state, pdaList: payload };
        case "SET_DD_REVIEW_STATUS":
            return { ...state, reviewStatus: payload };
        case "SET_DD_MATERIAL":
            return { ...state, materials: payload };
        case "SET_DD_PEOPLE":
            return { ...state, people: payload };
        case "SET_DD_REPORT_TYPE":
            return { ...state, reportType: payload };
        case "SET_DD_SHIP_STATUS":
            return { ...state, shipStatus: payload };
        case "SET_DD_APPOINTMENT":
            return { ...state, appointNum: payload };
        case "SET_DD_SERV_DOER":
            return { ...state, doer: payload };
        case "SET_DD_SERV_STATUS":
            return { ...state, servStatus: payload };
        default:
            return state;
    }

};
