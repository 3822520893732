import { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';

import HeaderMMS from '../../components/header/header';
import MenuMMS from '../../components/menu/menu';
import './configure.css';
import {
    area,
    facility,
    equipment,
    subEquipment,
    department,
    device,
    employee,
    jobNature,
    jobPriority,
    task,
    vendor,
    workPermit
} from '../../formData'
import TableFormDialog from '../../components/tableForm/tableFormDialog'

const Configure = () => {
    const [open, setOpen] = useState(false);
    const [menuName, setMenuName] = useState('area');

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const headerProps = {
        open, handleDrawerOpen
    }
    const menuProps = {
        setOpen, open
    }
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));
    const configList = {
        area: 'Area',
        facility: 'Facility',
        equipment: 'Equipment',
        subEquipment: 'Sub Equipment',
        department: 'Department',
        device: 'Device',
        employee: 'Employee',
        jobNature: 'Job Nature',
        jobPriority: 'Job Priority',
        task: 'Task',
        vendor: 'Vendor',
        workPermit: 'Work Permit',
        ert:"ERT",
    };
    const getConfigMenu = () => {
        const configMenu = [];
        for (const key in configList) {
            configMenu.push(<ListItem disablePadding>
                <ListItemButton className="mms-cnf-li" onClick={()=>{
                    setMenuName(key);
                }}>
                    <ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={configList[key]} />
                </ListItemButton>
            </ListItem>);
        }
        return configMenu;
    }

    return <Box sx={{ display: 'flex' }}>
        <HeaderMMS {...headerProps} />
        <MenuMMS {...menuProps} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
            <Grid className='bg-slate-100 '>
                <DrawerHeader />
                <div className='rounded p-3 text-lg text-left font-semibold'>
                    <h2>Configure</h2>
                </div>
                <div className="config-wrapper">
                    <div className="config-list">
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360, maxHeight: 'calc( 100vh - 160px )',
                                position: 'relative', overflow: 'auto',
                                bgcolor: 'background.paper'
                            }}
                            aria-label="contacts"
                        >
                            {getConfigMenu()}
                        </List>
                    </div>
                    <div className="config-content">
                        <TableFormDialog menuName={menuName} />
                    </div>
                </div>
            </Grid>
        </Box>
    </Box>;
};

export default Configure;
