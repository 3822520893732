import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';

export default function Settings() {
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
  
    const handleClose = () => {
      setOpenDialog(false);
    };
    const handleDrawerOpen = () => {
      setOpen(true);
    };
   
  const headerProps={
    open,handleDrawerOpen
  }
  const menuProps={
    setOpen,open
  }
  const tableProps={
    setOpenDialog
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
    return (
        <Box sx={{ display: 'flex' }}>
      
        <HeaderMMS {...headerProps} />
        <MenuMMS {...menuProps} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
        <Grid className='bg-slate-100 '>
        <DrawerHeader />
        {/* <Forms /> */}
        <div className='rounded p-3 text-lg text-left font-semibold'>
            <h2>Label Name Changes</h2>
            </div>
       
        
     
         </Grid>
        </Box>
      </Box>
    );

}