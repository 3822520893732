import React, { useMemo } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DateSelect from '../dateSelect/dateSelect';
const data = [
  {
    sno: 87302334,
    dateofPurchase: "22-Jun-2024",
    make: "Order",
    poNumber:"13Oct2024",
    invoiceNumber: "12940245",
    date: "22-Jun-2024",
    warrentyStart: "23-Jun-2024",
    warrentyEnd:"02-Oct-2024",
    warrentyPeriod: "2Year",
    expiryNotification: "Testing",
    vendor: "Amazon",
    contactName:"Sundhar",
    contactNumber: "89234932032",
    contactmail: "sundhar@yahoo.com ",
  },
  {
    sno: 87302334,
    dateofPurchase: "22-Jun-2024",
    make: "Order",
    poNumber:"13Oct2024",
    invoiceNumber: "12940245",
    date: "22-Jun-2024",
    warrentyStart: "23-Jun-2024",
    warrentyEnd:"02-Oct-2024",
    warrentyPeriod: "2Year",
    expiryNotification: "Testing",
    vendor: "Amazon",
    contactName:"Sundhar",
    contactNumber: "89234932032",
    contactmail: "sundhar@yahoo.com ",
  }
];
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function Tableworkorder({setOpenDialog}) {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.sno, 
        id: "sno",
        header: "S.NO",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.dateofPurchase, 
        id: "dateofPurchase",
        header: "Date of Purchase",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.make, 
        id: "make",
        header: "Make",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.poNumber, 
        id: "poNumber",
        header: "PO Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.invoiceNumber, 
        id: "invoiceNumber",
        header: "Invoice Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.date, 
        id: "date",
        header: "Date",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.warrentyStart, 
        id: "warrentyStart",
        header: "Warrenty  Start",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.warrentyEnd, 
        id: "warrentyEnd",
        header: "Warrenty End",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.warrentyPeriod, 
        id: "warrentyPeriod",
        header: "Warrenty Period",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.expiryNotification, 
        id: "expiryNotification",
        header: "Expiry Notification",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.vendor, 
        id: "vendor",
        header: "Vendor/Supplier",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.contactName, 
        id: "contactName",
        header: "Contact Name",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.contactNumber, 
        id: "contactNumber",
        header: "Contact Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.contactmail, 
        id: "contactmail",
        header: "Contact E-mail",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    data,
    columns,
     renderTopToolbarCustomActions : () => (<>
     <Box >
     <Grid className='bg-slate-100 '>
     
      <Button
          variant="contained" color="success"
          onClick={() => {setOpenDialog(true)
          }}
      >+ Add</Button> 
      <Button variant="contained" className='bg-amber-500 mx-0.5 mlr-5' >
      Export
    </Button>
    <Button variant="contained" className='bg-violet-500' >
      Import
    </Button>
    <DateSelect></DateSelect>
      </Grid>
      </Box>
  </>),
  });

  return <MaterialReactTable table={table} />;
}
