import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({
    dialogTitle,
    dialogContent,
    cancelText,
    okText,
    open,
    handleClose,
    handleCancelCB,
    handleSubmitCB
}) {

    const handleCancel = () => {
        handleCancelCB?.(open);
        handleClose();
    }
    const handleSubmit = () => {
        handleSubmitCB?.(open);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{cancelText}</Button>
                <Button onClick={handleSubmit} autoFocus>
                    {okText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}