import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
export default function Settings() {
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
  
    const handleClose = () => {
      setOpenDialog(false);
    };
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const [formData, setFormData] = useState({
        slno: '',
        dateofpurchase: null,
        make: '',
        poNumber: '',
        invoiceNumber: '',
        date: '',
        warrentystart: '',
        warrentyend: '',
        warrentyPeriod: '',
        expiryNotification: '',
        vendorSupplier: '',
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        dateinvoicenum: null,
        dateinvoice: null
      });
  const headerProps={
    open,handleDrawerOpen
  }
  const menuProps={
    setOpen,open
  }
  const tableProps={
    setOpenDialog
  }
  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date
    });
  };
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
   
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

   
  `,
  );
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
    return (
        <Box sx={{ display: 'flex' }}>
      
        <HeaderMMS {...headerProps} />
        <MenuMMS {...menuProps} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
        <Grid className='bg-slate-100 '>
        <DrawerHeader />
        {/* <Forms /> */}
        <div className='rounded p-3 text-lg text-left font-semibold'>
            <h2>Calibration Order</h2>
            </div>
       
            <Box className="w-full">
      <Grid className='bg-slate-100 '>
        <form>
          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200 '>Calibration Order</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3 mms-date-field'>
              
                <TextField size='small' label="S.No" 
                  name="S.No"
                   />

              <TextField size='small' label="Statutory Compliance" variant="outlined" name="StatutoryCompliance" />
              <FormControl >
                <InputLabel id="planFacility">Factory Licence</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Lifting Tools and Tackles</MenuItem>
                  <MenuItem value={20}>Contract Labour</MenuItem>
                  <MenuItem value={30}>PESO Licence</MenuItem>
                  <MenuItem value={40}>Environment (MoEF / CRZ / TNPCB)</MenuItem>
                  <MenuItem value={50}>Electrical Installations</MenuItem>
                  <MenuItem value={60}>Equipements Testing & Calibration</MenuItem>
                  <MenuItem value={70}>Weights & Measures</MenuItem>
                  <MenuItem value={80}>ROW</MenuItem>
                </Select>
              </FormControl>

              <FormControl >
                <InputLabel id="planFacility">Licence Number</InputLabel>
                <Select className='mms-select'
                  name="planFacility"   
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>TVR 9402</MenuItem>
                  <MenuItem value={20}>TVR 9402</MenuItem>
                </Select>
              </FormControl>
                 
              <TextField size='small' label="Responsible Dept" variant="outlined" name="StatutoryCompliance" />
              <FormControl >
                <InputLabel id="planFacility">Periodicity</InputLabel>
                <Select className='mms-select'
                  name="planFacility"   
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Once in a year</MenuItem>
                  <MenuItem value={20}>Once in 3 years</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Last Approval / Certification"
                    value={formData.dateinvoice}
                    onChange={(date) => handleDateChange(date, 'dateinvoice')}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Next Due"
                    value={formData.dateinvoice}
                    onChange={(date) => handleDateChange(date, 'dateinvoice')}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <Textarea aria-label="minimum height" minRows={2} placeholder="Contact" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Authority" />
            </div>

          </div>
        
          <div className='text-left bg-white  m-2 border-solid border rounded'>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-3 bg-gray-200 '>Near MISS</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3 mms-date-field'>
              
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date and Time"
                    value={formData.dateinvoice}
                    onChange={(date) => handleDateChange(date, 'dateinvoice')}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

              <TextField size='small' label="Location" variant="outlined" name="StatutoryCompliance" />
              <Textarea aria-label="minimum height" minRows={2} placeholder="Near Miss Description" />
              <Textarea aria-label="minimum height" minRows={2} placeholder="Witness if any" />
              <FormControl >
                <InputLabel id="planFacility">Near Miss Category</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Lifting Tools and Tackles</MenuItem>
                  <MenuItem value={20}>Contract Labour</MenuItem>
                  <MenuItem value={30}>PESO Licence</MenuItem>
                  <MenuItem value={40}>Environment (MoEF / CRZ / TNPCB)</MenuItem>
                  <MenuItem value={50}>Electrical Installations</MenuItem>
                  <MenuItem value={60}>Equipements Testing & Calibration</MenuItem>
                  <MenuItem value={70}>Weights & Measures</MenuItem>
                  <MenuItem value={80}>ROW</MenuItem>
                </Select>
              </FormControl>

              <TextField size='small' label="Person Involved" variant="outlined" name="StatutoryCompliance" />
              <Textarea aria-label="minimum height" minRows={2} placeholder="description of potential consequences" />
              <Textarea aria-label="minimum height" minRows={2} placeholder="Action Taken" />
              <FormControl >
                <InputLabel id="planFacility">Contributing Factor</InputLabel>
                <Select className='mms-select'
                  name="planFacility"   
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Once in a year</MenuItem>
                  <MenuItem value={20}>Once in 3 years</MenuItem>
                </Select>
              </FormControl>
              <Textarea aria-label="minimum height" minRows={3} placeholder="provincial Message" />
              <Textarea aria-label="minimum height" minRows={3} placeholder="Additional Message" />
              <TextField size='small' label="Reportel Name" variant="outlined" name="StatutoryCompliance" />
              <TextField size='small' label="Job Title / Role" variant="outlined" name="StatutoryCompliance" />
                <Textarea aria-label="minimum height" minRows={3} placeholder="Contact Details" />
               
            </div>

          </div>
        

          {/* <div className='mt-5 flex justify-center'>
            <div className=''>
              <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
            </div>
            <div className='mx-1'>
              <Button variant="contained" className='mx-1.5' >
                Add Similar
              </Button>
            </div>
            <div>
              <Button variant="contained" className='bg-amber-500' onClick={handleClearForm}>
                Clear
              </Button>
            </div>

          </div> */}
        </form>
      </Grid>
    </Box>
     
         </Grid>
        </Box>
      </Box>
    );

}