import React, { useMemo } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DateSelect from '../dateSelect/dateSelect';
const data = [
  {
    pmno: 87302334,
    area: "Test",
    facility: "Order",
    equipment:"13Oct2024",
    subEquipment: "12940245",
    device: "IOS",
    instrumentTag: "Test",
    jobNature:"True",
    lastPerformed: "Test",
    frequency: "Testing",
    tolerence: "Test",
    repeat:"Yes",
    advanceNotification: "Testing",
    status: "Completed",
  },
  {
    pmno: 87302334,
    area: "Test",
    facility: "Order",
    equipment:"13Oct2024",
    subEquipment: "12940245",
    device: "IOS",
    instrumentTag: "Test",
    jobNature:"True",
    lastPerformed: "Test",
    frequency: "Testing",
    tolerence: "Test",
    repeat:"Yes",
    advanceNotification: "Testing",
    status: "Completed",
  },
  {
    pmno: 87302334,
    area: "Test",
    facility: "Order",
    equipment:"13Oct2024",
    subEquipment: "12940245",
    device: "IOS",
    instrumentTag: "Test",
    jobNature:"True",
    lastPerformed: "Test",
    frequency: "Testing",
    tolerence: "Test",
    repeat:"Yes",
    advanceNotification: "Testing",
    status: "Completed",
  }
];

export default function Tableworkorder({setOpenDialog}) {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.pmno, 
        id: "pmno",
        header: "PM/No",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.area, 
        id: "area",
        header: "Area",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.facility, 
        id: "facility",
        header: "Facility",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.equipment, 
        id: "equipment",
        header: "Equipment",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.subEquipment, 
        id: "subEquipment",
        header: "Sub Equipment",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.device, 
        id: "device",
        header: "Device",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.instrumentTag, 
        id: "instrumentTag",
        header: "Instrument Tag",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.jobNature, 
        id: "jobNature",
        header: "Job Nature",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.lastPerformed, 
        id: "lastPerformed",
        header: "Last Performed",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.frequency, 
        id: "frequency",
        header: "Frequency",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.tolerence, 
        id: "tolerence",
        header: "Tolerence",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.repeat, 
        id: "repeat",
        header: "Repeat",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.advanceNotification, 
        id: "advanceNotification",
        header: "Advance Notification",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.status, 
        id: "status",
        header: "Status",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    data,
    columns,
     renderTopToolbarCustomActions : () => (<>
     <Box >
     <Grid className='bg-slate-100 '>
     
      <Button
          variant="contained" color="success"
          onClick={() => {setOpenDialog(true)
          }}
      >+ Add</Button> 
      <Button variant="contained" className='bg-amber-500 mx-0.5 mlr-5' >
      Export
    </Button>
    <Button variant="contained" className='bg-violet-500' >
      Import
    </Button>
    <DateSelect></DateSelect>
      </Grid>
      </Box>
  </>),
  });

  return <MaterialReactTable table={table} />;
}
