
export default function common(state = {}, { type, payload }) {
    switch (type) {
        case "SET_API_ALERT":
            return { ...state, apiAlert: payload };
        default:
            return state;
    }

};
