
export default {
    "formTitle": "Mock Form",
    "tableTitle": "Mock List",
    "tableCol": [
        {
            name: 'Still',
            selector: row => row.region,
            sortable: true
        },
        {
            name: 'Under',
            selector: row => row.code,
            sortable: true
        },
        {
            name: 'Development',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Still',
                    name: 'region',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Under',
                    name: 'code'
                },
                {
                    type: 'text',
                    label: 'Development',
                    name: 'code'
                }
            ]
        }
    ],
    "mockData": []
}